import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { LoginService } from './login.service';
import { throwError } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: LoginService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.authService.getAccessToken();
    const csrfToken = this.getCsrfTokenFromCookie();
    console.log("Here")
    console.log('Access Token:', accessToken);
    console.log('CSRF Token:', csrfToken);
  
    return from(this.handleRequestWithToken(req, accessToken, csrfToken, next));
  }
  

  private handleRequestWithToken(req: HttpRequest<any>, token: string | null, csrfToken: string | null, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers: { [key: string]: string } = {};
  
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
  
    if (csrfToken) {
      headers['X-XSRF-TOKEN'] = csrfToken;
    }
  
    if (Object.keys(headers).length > 0) {
      req = req.clone({ setHeaders: headers });
    }
  
    return next.handle(req).pipe(
      catchError(error => this.handleError(error, req, csrfToken, next))
    );
  }
  
  private handleError(error: any, req: HttpRequest<any>, csrfToken: string | null, next: HttpHandler): Observable<HttpEvent<any>> {
    if (error.status === 401) {
      return this.authService.refreshToken().pipe(
        switchMap(() => {
          const newToken = this.authService.getAccessToken();
          return this.handleRequestWithToken(req, newToken, csrfToken, next);
        })
      );
    }
    return throwError(error);
  }
  
  // Funktion zum Lesen des CSRF-Tokens aus dem Cookie
  private getCsrfTokenFromCookie(): string | null {
    const match = document.cookie.match(/(^|;\s*)XSRF-TOKEN=([^;]*)/);
    return match ? decodeURIComponent(match[2]) : null;
  }
  
}
