import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { ProductsService } from '../../../services/products.service';
import { CartService } from '../../../services/cart.service';
import { StorageService } from '../../../services/storage.service';
import { PopupComponent } from '../../popup/popup.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-bestseller',
  standalone: true,
  imports: [CommonModule, PopupComponent, RouterLink],
  templateUrl: './bestseller.component.html',
  styleUrl: './bestseller.component.scss',
})
export class BestsellerComponent implements OnInit {
   
    constructor(private productService: ProductsService, private storageService:StorageService) {}
  filteredProducts: any = [];
  activeFilter = 'all';
  cartService = inject(CartService);
  products: any = [];
  showPopup: boolean = false;

  openPopup() {
    this.showPopup = true;
  }

  closePopup() {
    this.showPopup = false;
  }
  ngOnInit(): void {
    this.getBestseller();
  }
  setDefaultImage(event: any): void {
    event.target.src = '../../../assets/img/coins/Default/coming_soon.jpg';
  }
  getBestseller() {
    this.productService.getBestseller().subscribe((res: any) => {
      this.products = res;
      this.filterProducts('all');
    });
  }
  filterProducts(filter: string): void {
    this.activeFilter = filter;
    if (filter === 'all') {
      this.filteredProducts = this.products;
    } else {
      this.filteredProducts = this.products.filter(
        (product: any) => product.category === filter
      );
    }
  }
  addToFavorite(product: any) {
    console.log(this.storageService.getCookie('cookies'));
    if (this.storageService.getCookie('cookies')[3] == '1') {
      this.cartService.addToFavorites(product);
    } else {
      this.openPopup()
    }
  }
}
